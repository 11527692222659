import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Auth, Tokens, AuthUser} from "../models/Auth";
import {catchError, EMPTY, firstValueFrom, Observable, throwError} from "rxjs";
import {LocalStorageUtil} from "../utils/localStorageUtil";
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../environments/environment.development";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedInUser: AuthUser | undefined;
  user: AuthUser | null;
  userToken: Tokens | null;

  constructor(private router: Router, private http: HttpClient, private toaster: ToastrService) {
    this.user = LocalStorageUtil.getItem<AuthUser>('user');
    this.userToken = LocalStorageUtil.getItem<Tokens>('userToken');
    console.log('Auth Service Initialized', this.user);

  }

  BASE_URL: string = environment.API_BASE_URL


  async login(email: string, password: string): Promise<void> {
    const body = { email, password };

    try {
      const data =await firstValueFrom(
        this.http.post<Auth>(`${this.BASE_URL}auth/login`, body).pipe(
          catchError(error => {
            this.handleError(error); // Handle HTTP errors within the catchError operator
            // Do not rethrow the error, instead return an EMPTY observable
            return EMPTY;
          })
        )
      );
      // Handle successful login
      this.handleLoginSuccess(data);

    } catch (error) {
      // No need to handle the error here since it has already been handled in catchError
    }
  }

  private handleLoginSuccess(data: Auth): void {
    this.loggedInUser = data.user;
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('userToken', JSON.stringify(data.tokens));
    localStorage.setItem('user', JSON.stringify(data.user));
    this.userToken = data.tokens;
    this.user = data.user;
    console.log('this data is now set', data);
    this.router.navigateByUrl('/project/calendar');
    console.log('Login successful:', data);
  }

  private handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      console.error('HTTP error occurred:', error.status, error.error);
      this.toaster.error(error.error.message, 'Failed');
    } else {
      console.error('Error occurred during login:', error);
      if (error.message) {
        this.toaster.error(error.message, 'Error');
      } else {
        this.toaster.error('Unknown error occurred', 'Error');
      }
    }
  }



  get isLoggedIn() {
    return localStorage.getItem('isLoggedIn') === 'true';
  }

  logout() {
    this.loggedInUser = undefined;
    LocalStorageUtil.clearStorage();
    this.router.navigateByUrl('/login')

  }
}
