import {Environment} from "@angular/cli/lib/config/workspace-schema";
import {environment} from "../../../environments/environment.development";

export const API_BASE_URL = environment.API_BASE_URL;
export const API_ROUTES = {
  addons: 'addons',
  auth: 'auth',
  bookings: 'bookings',
  banquets: 'banquets',
  companies: 'companies',
  customer: 'customer',
  dishes: 'dishes',
  inquiries: 'inquiries',
  menus: 'menus',
  users: 'users',
  vendors: 'vendors'

};
