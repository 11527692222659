import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import { LocalStorageUtil } from '../utils/localStorageUtil';
import { Inquiry } from '../models/Inquiry';
import {InquiryDetail, InquiryList} from '../models/InquiryList';
import { BookingRequest } from '../models/BookingRequest';
import { API_BASE_URL, API_ROUTES } from '../constants/apiConstants';
import {BookingList} from "../models/BookingList";
import {Banquets} from "../models/Banquets";
import {AddonList} from "../models/Addon";
import {BookingDetail} from "../models/Booking";
import {AuthService} from "./auth.service";
import {MenuList} from "../models/MenuList";
import {VendorList} from "../models/VendorList";
import {UserList} from "../models/UserList";
import {AuthUser} from "../models/Auth";
import {CompanyDetails} from "../models/CompanyDetails";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private authService: AuthService) {
    console.log('Api Service Initialized');
  }

  headers = new HttpHeaders().append(
    'Authorization',
    'Bearer ' + this.authService.userToken?.access.token
  );
  user: any = LocalStorageUtil.getItem<AuthUser>('user');

  // *************************** Inquiries *****************************************

  /**
   * Add Inquiry
   * @param inquiryBody
   */
  addInquiry(inquiryBody: any): Observable<InquiryDetail> {
    return this.http.post<InquiryDetail>(API_BASE_URL + API_ROUTES.inquiries, inquiryBody, { headers: this.headers });
  }

  /**
   * Get all inquiries
   */
  getAllInquiries(filter: string = ''): Observable<InquiryList> {
    console.log('getAllInquiries', this.headers);
    return this.http.get<InquiryList>(API_BASE_URL + API_ROUTES.inquiries + filter, { headers: this.headers });
  }
  /**
   * download csv all inquiries
   */
  downloadCsvInquiries(filter: string = '') {
    const url = API_BASE_URL + API_ROUTES.inquiries + filter;

    return this.http.get(url, { headers: this.headers, responseType: 'blob', observe: 'response' })
  }


  /**
   * Get single inquiry
   * @param id
   */
  getSingleInquiry(id: string): Observable<Inquiry> {
    return this.http.get<Inquiry>(API_BASE_URL + API_ROUTES.inquiries + '/' + id, { headers: this.headers });
  }

  /**
   * update single inquiry
   * @param id
   * @param body
   */
  updateSingleInquiry(id: string, body: any): Observable<Inquiry> {
    return this.http.patch<Inquiry>(API_BASE_URL + API_ROUTES.inquiries + '/' + id, body, { headers: this.headers });
  }

  /**
   * delete single inquiry
   * @param id
   */
  deleteSingleInquiry(id: string): Observable<Inquiry> {
    return this.http.delete<Inquiry>(API_BASE_URL + API_ROUTES.inquiries + '/' + id, { headers: this.headers });
  }

  // *************************** Inquiries End *****************************************

  // ***************************** Bookings Start ***********************************************

  /**
   * Add Booking
   * @param bookingBody
   */
  addBooking(bookingBody: BookingRequest): Observable<BookingDetail> {
    return this.http.post<BookingDetail>(API_BASE_URL + API_ROUTES.bookings, bookingBody, { headers: this.headers });
  }

  /**
   * Get all bookings
   */
  getAllBookings(filter: string = ''): Observable<BookingList> {
    console.log('getAllBookings', this.headers);
    return this.http.get<BookingList>(API_BASE_URL + API_ROUTES.bookings + filter, { headers: this.headers });
  }
  /**
   * download all bookings
   */
  downloadAllBookings(filter: string = ''): Observable<any> {
    console.log('getAllBookings', this.headers);
    const url = API_BASE_URL + API_ROUTES.bookings + filter;
    return this.http.get(url, { headers: this.headers, responseType: 'blob', observe: 'response' })
  }

  /**
   * Get single booking
   * @param id
   */
  getSingleBooking(id: string): Observable<BookingDetail> {
    return this.http.get<BookingDetail>(API_BASE_URL + API_ROUTES.bookings + '/' + id, { headers: this.headers });
  }

  /**
   * Update single booking
   * @param id
   * @param body
   */
  updateSingleBooking(id: string, body: BookingRequest | any): Observable<BookingDetail> {
    return this.http.patch<BookingDetail>(API_BASE_URL + API_ROUTES.bookings + '/' + id, body, { headers: this.headers });
  }

  /**
   * Update single booking by banquet
   * @param id
   * @param body
   */
  updateSingleBookingByBanquet(id: string, body: any): Observable<BookingDetail> {
    return this.http.patch<BookingDetail>(API_BASE_URL + API_ROUTES.bookings + '/banquet/' + id, body, { headers: this.headers });
  }

  /**
   * Delete single booking
   * @param id
   */
  deleteSingleBooking(id: string): Observable<BookingDetail> {
    return this.http.delete<BookingDetail>(API_BASE_URL + API_ROUTES.bookings + '/' + id, { headers: this.headers });
  }

  // ***************************** Bookings End ***********************************************

  // ***************************** Addons ***********************************************

  addAddon(addonBody: any): Observable<any> {
    return this.http.post<any>(API_BASE_URL + API_ROUTES.addons, addonBody, { headers: this.headers });
  }

  getAllAddons(): Promise<AddonList> {
    // return this.http.get<any[]>(API_BASE_URL + API_ROUTES.addons, { headers: this.headers });
    return firstValueFrom(this.http.get<AddonList>(API_BASE_URL + API_ROUTES.addons, { headers: this.headers }));

  }

  getSingleAddon(id: string): Observable<any> {
    return this.http.get<any>(API_BASE_URL + API_ROUTES.addons + '/' + id, { headers: this.headers });
  }

  updateSingleAddon(id: string, body: any): Observable<any> {
    return this.http.patch<any>(API_BASE_URL + API_ROUTES.addons + '/' + id, body, { headers: this.headers });
  }

  deleteSingleAddon(id: string): Observable<any> {
    return this.http.delete<any>(API_BASE_URL + API_ROUTES.addons + '/' + id, { headers: this.headers });
  }

  // ***************************** Banquets ***********************************************

  addBanquet(banquetBody: any): Observable<any> {
    return this.http.post<any>(API_BASE_URL + API_ROUTES.banquets, banquetBody, { headers: this.headers });
  }

  getAllBanquets(): Observable<any[]> {
    return this.http.get<any[]>(API_BASE_URL + API_ROUTES.banquets, { headers: this.headers });
  }

  getSingleBanquet(id: string): Observable<any> {
    return this.http.get<any>(API_BASE_URL + API_ROUTES.banquets + '/' + id, { headers: this.headers });
  }

  getMyBanquet(): Promise<Banquets[]> {
    // return this.http.get<Banquets[]>(API_BASE_URL + API_ROUTES.banquets + '/my' , { headers: this.headers });
    return firstValueFrom(this.http.get<Banquets[]>(API_BASE_URL + API_ROUTES.banquets + '/my', { headers: this.headers }));

  }

  updateSingleBanquet(id: string, body: any): Observable<any> {
    return this.http.patch<any>(API_BASE_URL + API_ROUTES.banquets + '/' + id, body, { headers: this.headers });
  }

  deleteSingleBanquet(id: string): Observable<any> {
    return this.http.delete<any>(API_BASE_URL + API_ROUTES.banquets + '/' + id, { headers: this.headers });
  }

  // ***************************** Companies ***********************************************

  addCompany(companyBody: any): Observable<any> {
    return this.http.post<any>(API_BASE_URL + API_ROUTES.companies, companyBody, { headers: this.headers });
  }

  getAllCompanies(): Observable<any[]> {
    return this.http.get<any[]>(API_BASE_URL + API_ROUTES.companies, { headers: this.headers });
  }

  getAllCompanyUsers(companyId: string = ''): Promise<any[]> {
    return firstValueFrom(this.http.get<any[]>(API_BASE_URL + API_ROUTES.companies + '/users/' + companyId, { headers: this.headers }));
  }

  getSingleCompany(id?: string): Promise<CompanyDetails> {
    return firstValueFrom(this.http.get<CompanyDetails>(API_BASE_URL + API_ROUTES.companies + '/' + id, { headers: this.headers }));
  }

  updateSingleCompany(id: string, body: any): Observable<any> {
    return this.http.patch<any>(API_BASE_URL + API_ROUTES.companies + '/' + id, body, { headers: this.headers });
  }

  deleteSingleCompany(id: string): Observable<any> {
    return this.http.delete<any>(API_BASE_URL + API_ROUTES.companies + '/' + id, { headers: this.headers });
  }

  getCalendarBanquets(companyId?: string): Promise<any> {
    return firstValueFrom(this.http.get<any>(API_BASE_URL + API_ROUTES.companies + '/calendar/' + companyId, { headers: this.headers }));
  }
  getCalendarBookings(companyId: string, banquetId: string, date: string): Observable<any> {
    // return this.http.get<any>(API_BASE_URL + API_ROUTES.companies + '/' + companyId, { headers: this.headers });
    return this.http.get<any>(API_BASE_URL  + API_ROUTES.companies + `/calendar/${companyId}?banquetId=${banquetId}&date=${date}`, { headers: this.headers });
  }



  // ***************************** Customers ***********************************************

  addCustomer(customerBody: any): Observable<any> {
    return this.http.post<any>(API_BASE_URL + API_ROUTES.customer, customerBody, { headers: this.headers });
  }

  getAllCustomers(): Observable<any[]> {
    return this.http.get<any[]>(API_BASE_URL + API_ROUTES.customer, { headers: this.headers });
  }

  getSingleCustomer(id: string): Observable<any> {
    return this.http.get<any>(API_BASE_URL + API_ROUTES.customer + '/' + id, { headers: this.headers });
  }

  updateSingleCustomer(id: string, body: any): Observable<any> {
    return this.http.patch<any>(API_BASE_URL + API_ROUTES.customer + '/' + id, body, { headers: this.headers });
  }

  deleteSingleCustomer(id: string): Observable<any> {
    return this.http.delete<any>(API_BASE_URL + API_ROUTES.customer + '/' + id, { headers: this.headers });
  }

  // ***************************** Dishes ***********************************************

  addDish(dishBody: any): Observable<any> {
    return this.http.post<any>(API_BASE_URL + API_ROUTES.dishes, dishBody, { headers: this.headers });
  }

  getAllDishes(): Observable<any[]> {
    return this.http.get<any[]>(API_BASE_URL + API_ROUTES.dishes, { headers: this.headers });
  }

  getSingleDish(id: string): Observable<any> {
    return this.http.get<any>(API_BASE_URL + API_ROUTES.dishes + '/' + id, { headers: this.headers });
  }

  updateSingleDish(id: string, body: any): Observable<any> {
    return this.http.patch<any>(API_BASE_URL + API_ROUTES.dishes + '/' + id, body, { headers: this.headers });
  }

  deleteSingleDish(id: string): Observable<any> {
    return this.http.delete<any>(API_BASE_URL + API_ROUTES.dishes + '/' + id, { headers: this.headers });
  }

  // ***************************** Menus ***********************************************

  addMenu(menuBody: any): Observable<any> {
    return this.http.post<any>(API_BASE_URL + API_ROUTES.menus, menuBody, { headers: this.headers });
  }

  getAllMenus(): Observable<MenuList> {
    return this.http.get<MenuList>(API_BASE_URL + API_ROUTES.menus, { headers: this.headers });
  }

  getSingleMenu(id: string): Promise<any> {
    // return this.http.get<any>(API_BASE_URL + API_ROUTES.menus + '/' + id, { headers: this.headers });
    return firstValueFrom(this.http.get<Banquets[]>(API_BASE_URL + API_ROUTES.menus + '/' + id, { headers: this.headers }));

  }

  updateSingleMenu(id: string, body: any): Observable<any> {
    return this.http.patch<any>(API_BASE_URL + API_ROUTES.menus + '/' + id, body, { headers: this.headers });
  }

  deleteSingleMenu(id: string): Observable<any> {
    return this.http.delete<any>(API_BASE_URL + API_ROUTES.menus + '/' + id, { headers: this.headers });
  }

  // ***************************** Users ***********************************************

  addUser(userBody: any): Observable<any> {
    return this.http.post<any>(API_BASE_URL + API_ROUTES.users, userBody, { headers: this.headers });
  }

  getAllUsers(): Observable<UserList> {
    return this.http.get<UserList>(API_BASE_URL + API_ROUTES.users + '?limit=200', { headers: this.headers });
  }

  getAllUsersPromise(queryParam: string = '?limit=100'): Promise<UserList> {
    return firstValueFrom(this.http.get<UserList>(API_BASE_URL + API_ROUTES.users + queryParam, { headers: this.headers }));
  }

  getSingleUser(id: string): Observable<any> {
    return this.http.get<any>(API_BASE_URL + API_ROUTES.users + '/' + id, { headers: this.headers });
  }

  updateSingleUser(id: string, body: any): Observable<any> {
    return this.http.patch<any>(API_BASE_URL + API_ROUTES.users + '/' + id, body, { headers: this.headers });
  }

  deleteSingleUser(id: string): Observable<any> {
    return this.http.delete<any>(API_BASE_URL + API_ROUTES.users + '/' + id, { headers: this.headers });
  }

  // ***************************** Auth ***********************************************

  login(credentials: { email: string, password: string }): Observable<any> {
    return this.http.post<any>(API_BASE_URL + API_ROUTES.auth + '/login', credentials, { headers: this.headers });
  }

  register(userBody: any): Observable<any> {
    return this.http.post<any>(API_BASE_URL + API_ROUTES.auth + '/register', userBody, { headers: this.headers });
  }

  logout(): Observable<void> {
    return this.http.post<void>(API_BASE_URL + API_ROUTES.auth + '/logout', {}, { headers: this.headers });
  }

  // *************************************** *Vendors *****************************************************

  getAllVendors(): Promise<VendorList> {
    // return this.http.get<any[]>(API_BASE_URL + API_ROUTES.addons, { headers: this.headers });
    return firstValueFrom(this.http.get<VendorList>(API_BASE_URL + API_ROUTES.vendors, { headers: this.headers }));
  }
  addVendor(body:any): Observable<any> {
    // return this.http.get<any[]>(API_BASE_URL + API_ROUTES.addons, { headers: this.headers });
    // return firstValueFrom(this.http.get<any>(API_BASE_URL + API_ROUTES.vendors, { headers: this.headers }));
    return this.http.post<any>(API_BASE_URL + API_ROUTES.vendors, body, { headers: this.headers });

  }

  getSingleVendor(id: string): Promise<any> {
    // return this.http.get<any>(API_BASE_URL + API_ROUTES.menus + '/' + id, { headers: this.headers });
    return firstValueFrom(this.http.get<Banquets[]>(API_BASE_URL + API_ROUTES.vendors + '/' + id, { headers: this.headers }));

  }

  updateSingleVendor(id: string, body: any): Observable<any> {
    return this.http.patch<any>(API_BASE_URL + API_ROUTES.vendors + '/' + id, body, { headers: this.headers });
  }

}
