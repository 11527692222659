import {Component, Input} from '@angular/core';
import {AsyncPipe, NgIf, NgStyle} from "@angular/common";
import {ProjectService} from "../../core/services/project.service";

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [
    NgStyle,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  @Input() size: number = 48; // Default size in pixels
  @Input() primaryColor: string = '#FFF'; // Default primary border color
  @Input() secondaryColor: string = '#FF3D00'; // Default secondary border color
  constructor(public projectService: ProjectService) { }
}
