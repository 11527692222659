import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {ApiService} from "./api.service";
import {Banquets} from "../models/Banquets";
import {Addon} from "../models/Addon";
import {AuthService} from "./auth.service";
import {ToastrService} from "ngx-toastr";
import {AuthUser} from "../models/Auth";
import {User} from "../models/UserList";
import {CompanyDetails} from "../models/CompanyDetails";

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  calendarBanquetList: any;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  myBanquets: Banquets[] = [];
  addons: Addon[] = []
  vendorList: any[] = [];
  banquet: Banquets = {
    location: {
      lat: 0,
      long: 0
    },
    name: '',
    menus: [],
    timings: [],
    floors: [],
    company: '',
    owners: [],
    address: '',
    phone: '',
    logo: '',
    image: '',
    map: '',
    primaryColor: '',
    secondaryColor: '',
    id: '',
    menuDetails: [],
    hallDetails: []
  };

// Usage with BehaviorSubject
  defaultBanquet: BehaviorSubject<Banquets> = new BehaviorSubject<Banquets>(this.banquet);
  allUsers: User[] = [];
  myCompany?: CompanyDetails;
  private bookingFormState = new BehaviorSubject<any>(null);
  private inquiryFormState = new BehaviorSubject<any>(null);


  constructor(private apiService: ApiService, private authService: AuthService, private toaster: ToastrService) {
    console.log('project service');
  }


  show() {
    // console.log('Loader shown');
    this.loadingSubject.next(true);
  }

  hide() {
    // console.log('Loader hidden');
    this.loadingSubject.next(false);
  }


  async getMyBanquets() {
    try {
      const banquets = await this.apiService.getMyBanquet();
      console.log('data', banquets);
      this.myBanquets = banquets;
      this.defaultBanquet.next(this.myBanquets[0]);
      await this.setFloorDetails(this.myBanquets);
      console.log('getMyBanquets() completed', this.myBanquets);
    } catch (error) {
      console.error('Error fetching banquets:', error);
    }
  }
  async getMyCompany() {
    try {
      this.myCompany = await this.apiService.getSingleCompany(this.authService.user?.companies[0]);
      console.log('data', this.myCompany);
    } catch (error) {
      console.error('Error fetching banquets:', error);
    }
  }

  async getCalendarBanquets() {
    try {
      this.calendarBanquetList = await this.apiService.getCalendarBanquets(this.authService.user?.companies[0]);
      await this.setFloorDetails(this.calendarBanquetList);
      console.log('getCalendarBanquets() completed', this.calendarBanquetList);
    } catch (error) {
      console.error('Error fetching calendar banquets:', error);
    }
  }


  async setFloorDetails(banquetList: Banquets[]) {
    for (const banquet of banquetList) {
      // Fetch menu details
      const menuDetailsPromises = banquet.menus.map(async (menuId) => {
        try {
          return await this.apiService.getSingleMenu(menuId);
        } catch (error) {
          console.error(`Error fetching menu ${menuId}:`, error);
          return null;
        }
      });
      banquet.menuDetails = (await Promise.all(menuDetailsPromises)).filter(detail => detail !== null);

      // Prepare hallWithFloorList for each banquet
      if (banquet.floors) {
        banquet.hallDetails = banquet.floors.flatMap((floor: any) =>
          floor.halls.map((hall: any) => ({
            ...hall,
            floorName: floor.name,
            floorId: floor.id,
            floorNumber: floor.number
          }))
        );
      }
    }
  }


  async getAddons() {
    try {
      const addonList = await this.apiService.getAllAddons();
      this.addons = addonList.docs;
    } catch (error) {

    }
  }

  async getVendors() {
    try {
      const vendorsList = await this.apiService.getAllVendors();
      this.vendorList = vendorsList.docs;
    } catch (error) {

    }
  }

  showSuccessToaster(title: string, message: string) {
    this.toaster.success(title, message)
  }

  showErrorToaster(title: string, message: string) {
    this.toaster.error(message, title)
  }

  async getUsers() {
    try {
      const userList = await this.apiService.getAllUsersPromise();
      this.allUsers = userList.docs;
      console.log('this is user List', userList);
      // this.allUsers = userList.docs;
    } catch (error) {

    }
  }
  async getCompanyUsers() {
    try {
      const userList = await this.apiService.getAllCompanyUsers(this.authService.user?.companies[0]);
      // this.allUsers = userList;
      console.log('this is user List', userList);
      this.allUsers = userList;
    } catch (error) {

    }
  }


  setBookingFormState(state: any) {
    this.bookingFormState.next(state);
  }

  getBookingFormState(): Observable<any> {
    return this.bookingFormState.asObservable();
  }

  setInquiryFormState(state: any) {
    this.inquiryFormState.next(state);
  }

  getInquiryFormState(): Observable<any> {
    return this.inquiryFormState.asObservable();
  }



}
